import Vue from "vue";
import axios from "axios";
import "url-search-params-polyfill";
import "es6-promise/auto";
import vueSmoothScroll from "vue-smoothscroll";

export default {
  data: function () {
    return {
      input: {
        select: "",
        name: "",
        kana: "",
        company: "",
        tel: "",
        email: "",
        content: "",
      },
      errors: {
        select: "",
        name: "",
        kana: "",
        company: "",
        tel: "",
        email: "",
        content: "",
      },
      isError: false,
    };
  },
  created: function () {},
  mounted: function () {},
  methods: {
    /* check_select: function () {
      const select = this.input.select;
      if (select.length === 0) {
        this.errors.select = "志望職種・お問い合わせを選択してください";
        this.isError = true;
      } else {
        this.errors.select = "";
      }
    }, */

    check_name: function () {
      const name = this.input.name.trim();

      if (name.length === 0) {
        this.errors.name = "氏名を入力してください";
        this.isError = true;
      } else {
        this.errors.name = "";
      }
    },

    check_company: function () {
      const company = this.input.company.trim();

      if (company.length === 0) {
        this.errors.company = "会社名を入力してください";
        this.isError = true;
      } else {
        this.errors.company = "";
      }
    },

    check_kana: function () {
      const kana = this.input.kana.trim();

      if (kana.length === 0) {
        this.errors.kana = "ふりがなを入力してください";
        this.isError = true;
      } else if (!kana.match(/^[ぁ-んー　]*$/)) {
        this.errors.kana = "ひらがなで入力してください";
        this.isError = true;
      } else {
        this.errors.kana = "";
      }
    },

    check_tel: function () {
      const tel = this.input.tel.trim();

      if (tel.length === 0) {
        this.errors.tel = "お電話番号を入力してください";
        this.isError = true;
      } else if (!tel.match(/^[0-9０-９\-ー]*$/)) {
        this.errors.tel = "お電話番号を正しく入力してください";
        this.isError = true;
      } else {
        this.errors.tel = "";
      }
    },

    check_email: function () {
      const email = this.input.email.trim();

      if (email.length === 0) {
        this.errors.email = "メールアドレスを入力してください";
        this.isError = true;
      } else if (
        !email.match(
          /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        )
      ) {
        this.errors.email = "正しく入力してください";
        this.isError = true;
      } else {
        this.errors.email = "";
      }
    },
    check_content: function () {
      const content = this.input.content.trim();

      if (content.length === 0) {
        this.errors.content = "お問い合わせ内容を入力してください";
        this.isError = true;
      } else {
        this.errors.content = "";
      }
    },

    submit: function () {
      this.isError = false;
      //this.check_select();
      this.check_name();
      this.check_kana();
      //this.check_company();
      this.check_tel();
      this.check_email();
      //this.check_content();

      if (!this.isError) {
        const params = new URLSearchParams();
        Object.keys(this.input).forEach(function (value) {
          params.append(value, this[value]);
        }, this.input);

        const instance = axios.create({
          headers: {
            "X-Requested-With": "XMLHttpRequest",
          },
        });

        instance
          .post("/assets/modules/form.php", params)
          .then(function (response) {
            location.href = "./fin.html";
          })
          .catch(function (error) {})
          .finally(function () {});
      }
    },
  },
};
