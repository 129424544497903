import Vue from "vue";
import vueSmoothScroll from "vue-smoothscroll";

import Form from "./form";

Vue.use(vueSmoothScroll);

new Vue({
  el: "#app",
  mixins: [Form],
  data: {
    modal_active: false,
  },
  created: function () {
    if ($("#product__scroll").length) {
      window.addEventListener("scroll", this.handleScroll);
    }
  },
  methods: {
    clickSmoothScroll(target) {
      this.$SmoothScroll(
        document.querySelector("#" + target),
        600,
        null,
        null,
        "y"
      );
    },
    clickScrollTop() {
      this.$SmoothScroll(0, 600, null, null, "y");
    },
    handleScroll() {
      const scrollY = window.scrollY;
      const windowHieght = window.innerHeight;
      const scrollBottom = scrollY + windowHieght;

      const target_top = $("#product__scroll").offset().top;
      const target_height = $("#product__scroll").innerHeight();

      const per = (
        scrollBottom /
        (target_top + target_height + windowHieght)
      ).toFixed(3);

      $("#scrollList").css({ transform: `translateX(${-50 * per}vw)` });
    },
  },
});

/* eslint-disable no-undef */
$(".toggle_btn").on("click", function () {
  $(this).toggleClass("is-open");
  $(this).parent().find(".toggle_content").stop().slideToggle();
});

$(function () {
  $(".serviceDetail__scroll").slick({ autoplay: true, arrows: false });
});



let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
// window resize
window.addEventListener('resize', () => {
  vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});